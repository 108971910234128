<template lang="pug">
.main-wrapper.resgate-creditos-list
    ConfirmDialog(:display='dialogConfirm.visible' :text='dialogConfirm.msg' @close='dialogConfirm.visible=false' @confirmed='removerComprovante()')
    Dialog.dialogDetalhesResgate(:modal="true" header= "Anexar Comprovante" :visible.sync="show")
        .p-grid.p-fluid
            .p-col-12.p-md-8(style="align-self: flex-end;")
                ProgressBar(v-if="waitingComprovante" mode="indeterminate")
                .p-inputgroup(v-else)
                    .p-input-icon-left.p-input-icon-right
                        InputText(
                            type="text"
                            placeholder="Anexar comprovante"
                            v-model="model.nm_comprovante"
                            @click="$refs.refComprovante.click()"
                            readonly)
                        i.jam.jam-attachment(@click="$refs.refComprovante.click()" v-tooltip.top="'Anexar'")
                        i.jam.jam-close(
                            v-show="sangria.aq_deposito"
                            @click="openConfirmRemoverComprovante()"
                            v-tooltip.top="'Remover anexo'")
                    Button(
                        :disabled="!sangria.aq_deposito"
                        icon="pi pi-external-link"
                        v-tooltip.top="'Vizualizar comprovante'"
                        @click="abrirComprovante()")

                input(
                    v-show='false'
                    type="file"
                    ref="refComprovante"
                    @change="nomeComprovante()"
                    accept=".")
            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_comprovante.$error }")
                label.form-label Código do Comprovante:
                InputText(v-model='$v.model.cd_comprovante.$model' type='number' @input='limitarCaracteres()')
                .feedback--errors(v-if='submitted && $v.model.cd_comprovante.$error')
                    .form-message--error(v-if="!$v.model.cd_comprovante.required") <b>Código do Comprovante</b> é obrigatório.

            .p-col-12
                Button(type='button' label='Salvar' @click='salvarComprovante()')

            .p-col-12.p-md-6
                span <b>Usuário criação: </b> {{ sangria.nm_usuario_cri }}
            .p-col-12.p-md-6
                span <b>Data da criação: </b> {{ sangria.dt_criado_f }}
            .p-col-12.p-md-6
                span <b>Usuário edição: </b> {{ sangria.nm_usuario_edi }}
            .p-col-12.p-md-6
                span <b>Data da atualização: </b> {{ sangria.dt_atualizado_f }}
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
    import { Caixas } from "@/middleware"
    import { required } from 'vuelidate/lib/validators'
    import InputNumber from 'primevue/inputnumber';

    // import { required, minValue } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Tooltip, Calendar, DataTable, Column, ConfirmDialog,
            InputNumber,
        },
        props: [
            'display',
            'sangria',
        ],
        computed: {
            show: {
                get() {
                    this.verificaSangria()
                    return this.display;
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        directives: { tooltip: Tooltip },
        data () {
            return {
                model: {
                    cd_comprovante: null,
                    aq_deposito: null,
                    nm_comprovante: '',
                },
                options: {

                },
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingComprovante: false,
                waitingForm: false,
                submitted: false,
                dialogConfirm: {
                    visible: false,
                    msg: '',
                },
            }
        },
        validations () {
            let v = {
                model: {
                    cd_comprovante: { required },
                }
            }
            
            return v
        },
        methods: {
            limitarCaracteres() {
                const maxLength = 10;
                if (this.model.cd_comprovante.length > maxLength) {
                    this.model.cd_comprovante = this.model.cd_comprovante.slice(0, maxLength);
                }
            },
            verificaSangria() {
                if(this.sangria.cd_comprovante) this.model.cd_comprovante = this.sangria.cd_comprovante
                if(this.sangria.aq_deposito) {
                    const aux1 = this.sangria.aq_deposito.lastIndexOf('/');
                    const aux2 = this.sangria.aq_deposito.lastIndexOf('?');
                    this.model.nm_comprovante = this.sangria.aq_deposito.substring(aux1 + 1, aux2);
                }
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            openConfirmRemoverComprovante() {
                this.dialogConfirm.msg = `Deseja mesmo REMOVER o comprovante anexado?`;
                this.dialogConfirm.visible = true;
            },
            nomeComprovante() {
                const files = this.$refs.refComprovante.files;
                const comprovante = files.length && files[0];

                this.model.aq_deposito = comprovante;
                this.model.nm_comprovante = comprovante.name
            },
            salvarComprovante() {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                if (this.model.aq_deposito == null) delete this.model.aq_deposito;

                this.waitingComprovante = true;
                Caixas.anexarComprovanteMovimento( this.sangria.id, this.model ).then(response => {
                    this.waitingComprovante = false;
                    if (response.status === 200) {
                        this.sangria.aq_deposito =
                            response.data.aq_deposito;
                        this.$toast.success(
                            "Comprovante de transferencia ANEXADO com sucesso."
                        );
                        this.$emit('close')
                        location.reload()
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data){
                            Object.values(response.data).forEach(error => {
                                error.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                            })
                        }
                    }
                });
            },
            removerComprovante() {
                this.waitingComprovante = true;
                Caixas.anexarComprovanteMovimento(
                    this.sangria.id,
                    {
                        aq_deposito: null,
                        cd_comprovante: this.model.cd_comprovante,
                    }
                ).then(response => {
                    this.waitingComprovante = false;
                    if (response.status === 200) {
                        this.sangria.nm_comprovante = "";
                        this.sangria.aq_deposito = null;
                        this.$refs.refComprovante.value = "";
                        this.$toast.info(
                            "Comprovante de transferencia REMOVIDO com sucesso."
                        );
                        location.reload()
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                });
            },
            abrirComprovante() {
                if (this.sangria.aq_deposito)
                    window.open(this.sangria.aq_deposito);
                else this.$toast.error("Link para o arquivo não encontrado.");
            },
         }
      }
</script>

<style lang="scss">
.resgate-creditos-list {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            &.ex {
                background-color: #e4f8e1;
            }
            &.a {
                background-color: #faf3dd;
            }
        }
        .p-progress-spinner {
            max-width: 23px;
            height: auto;
        }
    }
    .dialog-confirm {
        width: 300px;
        .p-dialog-content {
            padding-bottom: 6px;
        }
        .confirm-button {
            background-color: green;
        }
        .cancel-button {
            background-color: red;
            &:hover {
                background-color: rgb(122, 0, 0);
            }
        }
        .p-button.p-button-text {
            color: white;
            border-color: transparent;

            &:hover {
                background-color: transparent;
                color: black;
                border-color: black;
            }
        }
    }
    .dialogDetalhesResgate {
        width: 98%;
        max-width: 750px;
    }
    .highlight {
        background-color: #c9daf8;
        display: inline;
        font-weight: 700;

        &.fi {
            background-color: #c5efcb;
        }
        &.re {
            background-color: #f8d6d9;
        }
        &.so {
            background-color: #fff2cc;
        }
    }
    .text-small {
        .p-inputtext {
            font-size: 12px;
        }
        .form-label {
            font-size: 12px;
        }
    }
    .fieldset {
        border: 1px solid #c8c8c8;
    }
    .text-title {
        color: #657786;
        font-size: 14px;
        font-weight: 700;
    }
    .p-tooltip {
        max-width: none;
    }
    .waiting-small {
        max-width: 29px;
        height: auto;
    }
    .form-group--error .p-inputtextarea {
        border-color: #db5540;
    }
    .p-input-icon-left,
    .p-input-icon-right {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .p-input-icon-left > i,
    .p-input-icon-right > i {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        cursor: pointer;
    }
    .p-input-icon-left > i:first-of-type {
        left: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-right > i:last-of-type {
        right: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-left > .p-inputtext {
        padding-left: 2rem;
    }
    .p-input-icon-right > .p-inputtext {
        padding-right: 2rem;
    }
}
</style>