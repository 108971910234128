<template lang="pug">
.main-wrapper.historico-visualizar
	header.main-wrapper-header
		.p-grid.p-align-center
			.p-col-3
				Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
			.p-col-9.ta-right
				h1.text-header.text-secondary MedClub /
					<router-link :to='{ name: `${ this.supervisao ? "fechamento-caixa" : "historico-operacional" }` }'> Histórico de Fechamento</router-link> /
					<b>Visualizar</b>

	ImprimirRelatorio(:display='dialogImprimir' :tipo_caixa="supervisao ? 'SUP':'OPE'" :ctx="!telaOperacional ? 'SUP':'OPE'" :id='id' @close='dialogImprimir=false')
	FecharCaixa(v-if='model' :saldoCaixa='id' :operacional='!supervisao' :ctx="!telaOperacional ? 'SUP':'OPE'" :visible="dialogFechar" @close="dialogFechar = false" )
	RealizarSangria(:display='dialogSangria' :valorMax='model.saldo_atual' @close='dialogSangria=false')
	AnexarComprovanteSangria(v-if='dialogAnexo' :display='dialogAnexo' :sangria='this.itemSangria' @close='dialogAnexo=false')
	AdicionarQuebraCaixa(:display='dialogQuebra' :cdSaldo='id' @close='dialogQuebra=false')
	VerCancelarQuebraCaixa(:display='dialogVerCancelarQuebra' :obs='obsQuebra' :movimentoQuebra='movimentoQuebra' @close='dialogVerCancelarQuebra=false; obsQuebra=null')
	ConfirmDialog(v-if='modalConfirm.display' :display='modalConfirm.display' :text='modalConfirm.text' @close='modalConfirm.display=false' @confirmed='reabrirCaixa()')
	Panel.my-2(header='Histórico de Fechamento' style="vertical-align='center'")
		div.mb-4(v-if='!waiting')
			.mt-4.ta-right(v-if='model')
				.ml-2(style='display:inline-block' v-if='caixaAberto && supervisao && !movimentoQuebra')
					Button.p-button-success(:label="`Adicionar quebra`" @click="dialogQuebra = true")
				.ml-2(style='display:inline-block' v-if='caixaAberto && supervisao && movimentoQuebra')
					Button.p-button-danger(:label="`Remover quebra`" @click="dialogVerCancelarQuebra = true")
				.ml-2(style='display:inline-block' v-if='caixaAberto && !supervisao')
					Button.p-button-secondary(:label="`Sangria`" icon="jam jam-share" @click="dialogSangria = true")
				.ml-2(style='display:inline-block' v-if='caixaAberto')
					Button.p-button-warning(label='Fechar caixa' icon="jam jam-power" @click="dialogFechar = true")
				.ml-2(style='display:inline-block' v-if='!caixaAberto && supervisao')
					Button(label='Reabrir caixa' icon="jam jam-screen" @click="dialogModalConfirm()")
				.ml-2(style='display:inline-block')
					Button(label='Imprimir relatório' icon="jam jam-printer" @click="dialogImprimir = true")
		.ta-center(v-if='waiting')
			ProgressSpinner.spinner(strokeWidth="2")
		.__box-imprimir(v-else-if='model' style='margin: 0 auto; max-width: 720px;')
			fieldset
				legend.text-title Informações
				.p-grid.p-align-center.mb-3.mt-2.ml-1
					.p-col-6
						p <b>Caixa:</b> {{ model.nm_caixa }}
						p <b>Unidade:</b> {{ model.nm_unidade }}
						p <b>Setor:</b> {{ model.nm_setor }}
						p <b>Sala:</b> {{ model.nm_sala }}
					.p-col-6
						p <b>Usuário de abertura:</b> {{ model.nm_usuario_abertura }}
						p <b>Data de abertura:</b> {{ model.dt_abertura_f }}
						p <b>Usuário de fechamento:</b> {{ model.nm_usuario_fechamento }}
						p <b>Data de fechamento:</b> {{ model.dt_fechamento_f }}
			fieldset
				legend.text-title Resumo
				.p-grid.p-align-top.mb-3.mt-2.ml-1
					.p-col-6
						p(v-for="item in model.resumo1" )
							<b>{{ item.descricao }}:</b> <span :class="{'highlight': item.destacar}">{{ formatPrice(item.valor) }}</span>
					.p-col-6
						p(v-for="item in model.resumo2" )
							<b>{{ item.descricao }}:</b> <span :class="{'highlight': item.destacar}">{{ formatPrice(item.valor) }}</span>

			fieldset(v-if="model.sangrias.length")
				legend.text-title Sangrias
				DataTable.datatable-list(:value='model.sangrias')
					Column(headerStyle='width: 40%;' bodyStyle='text-align: right' header='Comprovante')
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Comprovante'"
									icon="jam jam-attachment"
									@click="openArquivo(props.data)"
								)
					Column(headerStyle='width: 50%;' bodyStyle='text-align: center' field='dt_criado_f' header='Data / Horário' sortable)
					Column(headerStyle='width: 18%;' bodyStyle='text-align: right' field='nr_valor' header='Valor' sortable)
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
					ColumnGroup(type="footer")
						Row
							Column(footer="Total:" :colspan="2" :footerStyle="{'text-align':'right'}")
							Column(:footer="formatPrice(model.total_sangrias)" :footerStyle="{'text-align':'right'}")


			fieldset(v-if="model.estornos.length")
				legend.text-title Estornos
				DataTable.datatable-list(:value='model.estornos')
					Column(headerStyle='width: 20%;' bodyStyle='text-align: center' field='cd_transacao_financeira' header='Transação' sortable)
					Column(headerStyle='width: 27%;' bodyStyle='text-align: center' field='dt_criado_f' header='Data / Horário' sortable)
					Column(headerStyle='width: 25%;' bodyStyle='text-align: center' field='forma_pagamento' header='Forma de Pagamento' sortable)
					Column(headerStyle='width: 16%;' bodyStyle='text-align: right' header='Valor' field='nr_valor_f' sortable)
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
					ColumnGroup(type="footer")
						Row
							Column(footer="Total:" :colspan="3" footerStyle="text-align: right")
							Column(:footer="formatPrice(model.total_estornos)" footerStyle="text-align: right")


			fieldset(v-if="model.movimentos.length")
				legend.text-title Movimentos
				DataTable.datatable-list(:value='model.movimentos')
					Column(headerStyle='width: 21%;' bodyStyle='text-align: center' field='cd_transacao_financeira' header='Transação' sortable)
						template(#body='props')
							span {{ props.data.cd_transacao_financeira ? props.data.cd_transacao_financeira : options.ie_tipo_caixa[props.data.ie_tipo_movimento] || props.data.ie_tipo_movimento }}
							Button.small.p-button-rounded.p-button-outlined.p-button-warning(v-if="!props.data.cd_transacao_financeira && props.data.ds_observacao && ['QCP', 'QCN', 'CQC'].includes(props.data.ie_tipo_movimento)" icon="jam jam-info" @click="obsQuebra = props.data.ds_observacao; dialogVerCancelarQuebra=true")
					Column(headerStyle='width: 28%;' bodyStyle='text-align: center' field='dt_criado_f' header='Data / Horário' sortable)
					Column(headerStyle='width: 24%;' bodyStyle='text-align: center' field='forma_pagamento' header='Forma de pagamento' sortable)
					Column(headerStyle='width: 14%;' bodyStyle='text-align: center' field='nr_credito_parcelas' header='Parcelas' sortable)
						template(#body='props')
							span {{ props.data.nr_credito_parcelas == 1 ? "À vista" : props.data.nr_credito_parcelas }}
					Column(headerStyle='width: 16%;' bodyStyle='text-align: right' header='Valor' field='nr_valor' sortable)
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
					Column(headerStyle='width: 16%;' bodyStyle='text-align: right' header='Juros' field='nr_valor_juros' sortable)
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor_juros < 0 }") {{ formatPrice(props.data.nr_valor_juros) }}
					ColumnGroup(type="footer")
						Row
							Column(footer="Total:" :colspan="4" footerStyle="text-align: right")
							Column(:footer="formatPrice(model.total_movimentos)" footerStyle="text-align: right")
							Column(:footer="formatPrice(model.total_juros)" footerStyle="text-align: right")

			fieldset(v-if="model.transacoes.length")
				legend.text-title Transações
				DataTable.datatable-list(:value='model.transacoes')
					Column(headerStyle='width: 20%;' bodyStyle='text-align: center' field='id' header='Transação' sortable)
					Column(headerStyle='width: 30%;' field='nm_pagante' header='Pagante' sortable)
					Column(headerStyle='width: 17%;' field='nr_cpf_pagante_f' header='CPF' sortable)
					Column(headerStyle='width: 17%;' field='qtd_guias' header='Qtd. guias' bodyStyle='text-align:center' sortable)
					Column(headerStyle='width: 16%;' bodyStyle='text-align: right' header='Valor' field='nr_valor' sortable)
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
					ColumnGroup(type="footer")
						Row
							Column(footer="Total:" :colspan="4" footerStyle="text-align: right")
							Column(:footer="formatPrice(model.total_transacoes)" footerStyle="text-align: right")
</template>

<style lang="scss">
	.historico-visualizar {
		.small {
			font-size: 10px;
			margin-left: 4px;
		}
		.spinner {
			margin: 100px 0;
		}
		.text-footer {
			font-size: 12px;
			margin-top: -10px !important;
		}
		.img-logo {
			max-width: 160px;
			@media screen {
				display: none;
			}
		}
		.__box-imprimir {
			border: 1px solid #c8c8c8;
			padding: 20px;
			fieldset {
				border: 1px solid #c8c8c8;
			}
			p {
				margin: 2px 0;
				font-size: 12px;
				color: #333;
			}
			.text-title {
				color: #657786;
				font-size: 14px;
				font-weight: 700;
			}
			.table {
				border-bottom: 1px solid #c8c8c8;
				.row {
					font-size: 0;
					border-top: 1px solid #c8c8c8;
					border-left: 1px solid #c8c8c8;
					.col {
						display: inline-block;
						vertical-align: middle;
						font-size: 12px;
						border-right: 1px solid #c8c8c8;
						padding: 2px 6px;
					}
				}
			}
			.p-progress-spinner {
				height: 29px;
				width: 29px;
			}
			@media print {
				.box-hide { display: none }
			}
			.datatable-list {
				.p-column-title, td {
					font-size: 12px;
				}
			}
			.highlight {
				color: #007ad9;
				font-weight: 700;
			}
		}
	}
</style>

<script>
	import Panel from 'primevue/panel'
	import Button from 'primevue/button'
	import ProgressSpinner from 'primevue/progressspinner'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import ColumnGroup from 'primevue/columngroup'
	import Row from 'primevue/row'
	import Tooltip from 'primevue/tooltip'

	import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
	import { Caixas } from '../../middleware'
	import FecharCaixa from './../Caixa/FecharCaixa'
	import RealizarSangria from './../Caixa/RealizarSangria'
	import AnexarComprovanteSangria from './../Caixa/AnexarComprovanteSangria'
	import Message from 'primevue/message'
	import { DominioValor } from './../../middleware'
	import ImprimirRelatorio from './../Caixa/ImprimirRelatorio'
	import AdicionarQuebraCaixa from './../Caixa/AdicionarQuebraCaixa'
	import VerCancelarQuebraCaixa from './../Caixa/VerCancelarQuebraCaixa'

	import moment from 'moment'
	export default {
		data () {
			return {
				model: {
					resumo: [],
					movimentos: [],
					transacoes: [],
					estornos: [],
					sangrias: [],
				},
				options: {
					ie_tipo_caixa: {}
				},
				id: null,
				movimentoQuebra: null,
				itemSangria: null,
				waiting: false,
				dialogFechar: false,
				modalConfirm: {
					text: '',
					display: false,
				},
				dialogSangria: false,
				dialogAnexo: false,
				dialogImprimir: false,
				dialogQuebra: false,
				dialogVerCancelarQuebra: false,
                telaOperacional: false,
				caixaAberto: true,
				supervisao: false,
				obsQuebra: null,
			}
		},
		components: {
			Panel, Button, ProgressSpinner, FecharCaixa, DataTable, Column, ColumnGroup, Row, RealizarSangria, AnexarComprovanteSangria, Message,
			ConfirmDialog, Tooltip, ImprimirRelatorio, AdicionarQuebraCaixa, VerCancelarQuebraCaixa
		},
		directives: { tooltip: Tooltip },
		methods: {
			dialogModalConfirm() {
				this.modalConfirm.text = "Você está prestes a reabrir um caixa já fechado anteriormente. Deseja prosseguir com essa ação?"
				this.modalConfirm.display = true
			},
			getDominio(){
				DominioValor.findAll({ds_mnemonico: 'TIPO_MOVIMENTO_CAIXA'}).then((response) => {
					if(response.status === 200) {
						response.data['TIPO_MOVIMENTO_CAIXA'].valores.forEach(item => {
							this.options.ie_tipo_caixa[item.ie_valor] = item.ds_valor
						})
					}
				})
			},
			verificarRota() {
				let rotaAtual = this.$route.path.split('/')[1]
				this.supervisao = rotaAtual === "fechamento-caixa"
			},
			verificarQuebra(){
				if (this.model.quebras_caixa.length > 0 && ["QCP", "QCN"].includes(this.model.quebras_caixa[0].ie_tipo_movimento)) this.movimentoQuebra = this.model.quebras_caixa[0]
			},
            verificarTroco(){
                this.telaOperacional = !("saldo_troco" in this.model)
            },
			reabrirCaixa(){
				if (isNaN(this.$route.params.id)) this.$router.go(-1)
				let saldoCaixa = parseInt(this.$route.params.id)

				Caixas.reabrirCaixa(saldoCaixa).then(response => {
					if (response.status === 200) {
                        this.$toast.success(
                            "Caixa REABERTO com sucesso."
                        );
                        location.reload()
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                })
			},
			openArquivo(params){
				this.dialogAnexo = true
				this.itemSangria = params
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
		},
		created () {
			let usuario = JSON.parse(localStorage.getItem('usuario'))
			this.usuarioNivel = usuario.nivel

			if (isNaN(this.$route.params.id)) this.$router.go(-1)
			this.id = parseInt(this.$route.params.id)

			this.verificarRota()
			this.getDominio()

			this.waiting = true
			Caixas.findFechamentoOperacional(this.id, {ie_detalhado: true}).then(response => {
				if (response.status === 200) {
					this.model = response.data
					this.model.resumo1 = this.model.resumo.slice(0, Math.ceil( this.model.resumo.length/2 ))
					this.model.resumo2 = this.model.resumo.slice( Math.ceil( this.model.resumo.length/2 ))
					this.model.dt_abertura_f = moment(this.model.dt_abertura).format('DD/MM/YYYY HH:mm')
					if (this.model.dt_fechamento) {
						this.model.dt_fechamento_f = moment(this.model.dt_fechamento).format('DD/MM/YYYY HH:mm')
						this.caixaAberto = false
					}
					this.model.transacoes.forEach(transacao => {
						transacao.nr_valor_f = this.formatPrice(transacao.nr_valor)
						if (transacao.nr_cpf_pagante) transacao.nr_cpf_pagante_f =
							transacao.nr_cpf_pagante.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
					})
					this.model.movimentos.forEach(item => {
						item.dt_criado_f = moment(item.dt_criado).format('DD/MM/YYYY HH:mm:ss')
						item.dt_movimento_f = moment(item.dt_movimento).format('DD/MM/YYYY')
						item.nr_valor_f = this.formatPrice(item.nr_valor)
					})
					this.model.estornos.forEach(item => {
						item.dt_criado_f = moment(this.model.dt_criado).format('DD/MM/YYYY HH:mm:ss')
					})
					this.model.sangrias.forEach(item => {
						if(item.dt_criado) item.dt_criado_f = moment(item.dt_criado).format('DD/MM/YYYY HH:mm:ss')
						if(item.dt_atualizado) item.dt_atualizado_f = moment(item.dt_atualizado).format('DD/MM/YYYY HH:mm:ss')
					})
					this.verificarQuebra()
					this.verificarTroco()
				}
				this.waiting = false
			})
		}
	}
</script>
