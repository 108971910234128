<template lang="pug">
Dialog.dialog-sangria(:modal="true" header= "Adicionar Quebra de Caixa" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    ConfirmDialog(:display='dialogConfirm' :text="'Você está prestes a adicionar uma quebra de caixa. Deseja prosseguir com essa ação?'" @close='dialogConfirm=false' @confirmed='handleSubmit()')
    form(header="Adicionar Quebra de Caixa" )
        .p-grid.p-fluid.p-align-top.p-justify-center
            .p-col-12
                label.form-label Tipo de Quebra:
                SelectButton(v-model='$v.model.tipoQuebra.$model' :options='options.tipoQuebraOption' optionValue='value' dataKey='value' optionLabel='label')
                .feedback--errors(v-if='submitted && $v.model.tipoQuebra.$error')
                    .form-message--error(v-if="!$v.model.tipoQuebra.required") <b>Tipo de quebra</b> é obrigatório.
            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.nr_valor_f.$error }")
                label.form-label Valor:
                .p-inputgroup
                    span.p-inputgroup-addon {{'R$'}}
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        v-model='$v.model.nr_valor_f.$model' locale='pt-BR' :auto-decimal-mode='true'
                        :value-range='{ min: 0 }')
                .feedback--errors(v-if='submitted && $v.model.nr_valor_f.$error')
                    .form-message--error(v-if="!$v.model.nr_valor_f.required") <b>Valor</b> é obrigatório.
                    .form-message--error(v-if="!$v.model.nr_valor_f.minValue") <b>O valor</b> precisa ser maior que <b> 0 </b>.
            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_observacao.$error }")
                label.form-label Observação:
                Textarea(type='text' rows="3" v-model='$v.model.ds_observacao.$model')
                .feedback--errors(v-if='submitted && $v.model.ds_observacao.$error')
                    .form-message--error(v-if="!$v.model.ds_observacao.required") <b>Observação</b> é obrigatório.
            .p-col-12.ta-right
                    Button(label='Salvar' type="button" @click='dialogConfirm=true')
    </template>
    
    <script>
        import Dialog from 'primevue/dialog';
        import ProgressBar from 'primevue/progressbar'
        import Panel from 'primevue/panel'
        import InputText from 'primevue/inputtext'
        import InputMask from 'primevue/inputmask'
        import Password from 'primevue/password'
        import Button from 'primevue/button'
        import Dropdown from 'primevue/dropdown'
        import SelectButton from 'primevue/selectbutton'
        import Calendar from 'primevue/calendar'
        import DataTable from 'primevue/datatable'
        import Column from 'primevue/column'
        import { Caixas } from "@/middleware";
        import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
        import Textarea from 'primevue/textarea';
    
        import { required, minValue } from 'vuelidate/lib/validators'
        import { pCalendarLocale_ptbr } from './../../utils'
    
        export default {
            components: {
                ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column,
                ConfirmDialog, Textarea,
            },
            props: [
                'display',
                'cdSaldo',
            ],
            computed: {
                show: {
                    get() { 
                        if (!this.display) this.resetModel()
                        return this.display; 
                    },
                    set(value) { if (!value) this.$emit('close'); }
                },
            },
            data () {
                return {
                    model: {
                        nr_valor: null,
                        tipoQuebra: null,
                        ds_observacao: '',
                        nr_valor_f: null,
                    },
                    options: {
                        tipoQuebraOption: [
                            {
                                label: "Positivo",
                                value: "P",
                            },
                            {
                                label: "Negativo",
                                value: "N",
                            }
                        ]
                    },
                    ptbr: pCalendarLocale_ptbr,
                    waiting: true,
                    waitingForm: false,
                    submitted: false,
                    dialogConfirm: false,
                }
            },
            validations () {
                let v = {
                    model: {
                        nr_valor_f: {
                            required,
                            minValue: minValue(0.01),
                        },
                        tipoQuebra: { required },
                        ds_observacao: { required }
                    }
                }
                return v
            },
            methods: {
                resetModel() {
                    this.model.nr_valor = null
                    this.waiting = true
                    this.waitingForm = false
                    this.submitted = false
                    this.dialogConfirm = false
                    this.model.tipoQuebra = null
                    this.model.ds_observacao = ''
                    this.model.nr_valor_f = null
                },
                formatPrice (val) {
                    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
                },
                handleSubmit () {
                    this.submitted = true
                    this.$v.$touch()
                    if (this.$v.$invalid) return 0

                    this.model.nr_valor = this.model.tipoQuebra === "N" ? this.model.nr_valor_f * -1 : this.model.nr_valor_f
    
                    Caixas.quebraCaixa({ nr_valor: this.model.nr_valor, cd_saldo_caixa: this.cdSaldo, ds_observacao: this.model.ds_observacao }).then(response => {
                        this.submitted = false;
                        if (([200, 201]).includes(response.status)) {
                            if(this.model.tipoQuebra === "P") {
                                this.$toast.success(
                                    "Quebra de caixa POSITIVO realizado com sucesso."
                                );
                            }
                            else {
                                this.$toast.success(
                                    "Quebra de caixa NEGATIVO realizado com sucesso."
                                );
                            }
                            this.$emit('close')
                            location.reload()
                        }
                        else if (response.status === 400) {
                            if (response.data.detail) {
                                if (typeof response.data.detail == 'string') {
                                    this.$toast.error(response.data.detail, { duration: 3000 });
                                } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                            } else if (response.data.non_field_errors) {
                                response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                            }
                        }
                    });
                }
            },
        }
    </script>
    
    <style lang="scss">
    
    .dialog-sangria {
        width: 600px;
        .p-dialog-content {
            padding-bottom: 6px;
        }
        .confirm-button {
            background-color: green;
        }
        .cancel-button {
            background-color: red;
        }
        .p-button.p-button-text {
            color: white;
            border-color: transparent;
    
            &:hover {
                background-color: transparent;
                color: black;
                border-color: black;
            }
        }
    }
    </style>
    