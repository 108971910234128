<template lang="pug">
Dialog.dialog-sangria(:modal="true" :header="(obs ? 'Observação da' : 'Cancelar') + ' Quebra de Caixa'" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    ConfirmDialog(:display='dialogConfirm' :text="'Você está prestes a cancelar a última quebra de caixa realizada. Deseja prosseguir com essa ação?'" @close='dialogConfirm=false' @confirmed='handleSubmit()')
    form(header=`${ obs ? 'Observação da' : 'Cancelar' } Quebra de Caixa`)
        .p-grid.p-fluid.p-align-top.p-justify-center
            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_observacao.$error }")
                label.form-label Observação:
                Textarea(type='text' rows="3" v-model='$v.model.ds_observacao.$model' :disabled='obs')
                .feedback--errors(v-if='submitted && $v.model.ds_observacao.$error')
                    .form-message--error(v-if="!$v.model.ds_observacao.required") <b>Observação</b> é obrigatório.
            .p-col-12.ta-right
                    Button(label='Confirmar' type="button" @click='obs ? show=false : dialogConfirm=true')
        </template>
        
        <script>
            import Dialog from 'primevue/dialog';
            import ProgressBar from 'primevue/progressbar'
            import Panel from 'primevue/panel'
            import InputText from 'primevue/inputtext'
            import InputMask from 'primevue/inputmask'
            import Password from 'primevue/password'
            import Button from 'primevue/button'
            import Dropdown from 'primevue/dropdown'
            import SelectButton from 'primevue/selectbutton'
            import Calendar from 'primevue/calendar'
            import DataTable from 'primevue/datatable'
            import Column from 'primevue/column'
            import { Caixas } from "@/middleware";
            import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
            import Textarea from 'primevue/textarea';
        
            import { required } from 'vuelidate/lib/validators'
            import { pCalendarLocale_ptbr } from './../../utils'
        
            export default {
                components: {
                    ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column,
                    ConfirmDialog, Textarea,
                },
                props: [
                    'display',
                    'movimentoQuebra',
                    'obs'
                ],
                computed: {
                    show: {
                        get() { 
                            if (!this.display) this.resetModel()
                            else this.VerificarObs()
                            return this.display; 
                        },
                        set(value) { if (!value) this.$emit('close'); }
                    },
                },
                data () {
                    return {
                        model: {
                            ds_observacao: '',
                        },
                        ptbr: pCalendarLocale_ptbr,
                        waiting: true,
                        waitingForm: false,
                        submitted: false,
                        dialogConfirm: false,
                    }
                },
                validations () {
                    let v = {
                        model: {
                            ds_observacao: { required }
                        }
                    }
                    return v
                },
                methods: {
                    resetModel() {
                        this.waiting = true
                        this.waitingForm = false
                        this.submitted = false
                        this.dialogConfirm = false
                        this.model.ds_observacao = ''
                    },
                    VerificarObs () {
                        if ( this.obs ) this.model.ds_observacao = this.obs
                    },
                    handleSubmit () {
                        this.submitted = true
                        this.$v.$touch()
                        if (this.$v.$invalid) return 0

                        Caixas.cancelarQuebra({ id: this.movimentoQuebra.id, ds_observacao: this.model.ds_observacao }).then(response => {
                            this.submitted = false;
                            if (([200, 201, 204]).includes(response.status)) {
                                this.$toast.success(
                                    "Quebra de caixa REMOVIDO com sucesso."
                                );
                                this.$emit('close')
                                location.reload()
                            }
                            else if (response.status === 400) {
                                if (response.data.detail) {
                                    if (typeof response.data.detail == 'string') {
                                        this.$toast.error(response.data.detail, { duration: 3000 });
                                    } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                                } else if (response.data.non_field_errors) {
                                    response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                                }
                            }
                        });
                    }
                },
            }
        </script>
        
        <style lang="scss">
        
        .dialog-sangria {
            width: 600px;
            .p-dialog-content {
                padding-bottom: 6px;
            }
            .confirm-button {
                background-color: green;
            }
            .cancel-button {
                background-color: red;
            }
            .p-button.p-button-text {
                color: white;
                border-color: transparent;
        
                &:hover {
                    background-color: transparent;
                    color: black;
                    border-color: black;
                }
            }
        }
        </style>
        